import Empty from "@/components/Empty";
import Loader from "@/components/Loader";
import NavBar from "@/components/NavBar";
import { OrderStatusEnum, OrderStatusEnumMaps } from "@/enums";
import orderModel from "@/models/order.model";
import { useInfiniteScroll, useReactive } from "ahooks";
import dayjs from "dayjs";
import KeepAlive from "keepalive-for-react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useCopyToClipboard } from "react-use";
import {
  CountDown,
  Image,
  List,
  PullRefresh,
  Sticky,
  Tabs,
  Toast,
} from "react-vant";
import styles from "./index.module.css";

const ListView = ({
  status,
  onClick,
}: {
  status: OrderStatusEnum;
  onClick?: (item: any) => Promise<void>;
}) => {
  const [_, copy] = useCopyToClipboard();
  const { data, loading, loadMoreAsync, reloadAsync, noMore } =
    useInfiniteScroll(
      (d) => {
        return orderModel
          .list({
            params: {
              status,
              current: d ? d?.current + 1 : 1,
              pageSize: 20,
            },
          })
          .then((res) => res?.data);
      },
      {
        reloadDeps: [status],
        isNoMore: (d) => (d ? !d?.hasMore : true),
      }
    );
  return (
    <PullRefresh onRefresh={reloadAsync}>
      {loading ? (
        <Loader />
      ) : !data || data?.list?.length <= 0 ? (
        <Empty />
      ) : (
        <List
          className={styles.list}
          finished={noMore}
          finishedText="没有更多数据了～"
          onLoad={async () => {
            await loadMoreAsync();
          }}
        >
          {data?.list?.map((item) => {
            return (
              <div
                key={item.id}
                className={styles.item}
                onClick={() => onClick && onClick(item)}
              >
                <div className={styles.header}>
                  {item.status === OrderStatusEnum.PENDING ? (
                    <>
                      <span>
                        {OrderStatusEnumMaps[item.status as OrderStatusEnum]}
                      </span>

                      {dayjs(item.expireTime).diff(dayjs()) > 0 && (
                        <CountDown
                          className={styles.countdown}
                          time={dayjs(item.expireTime).diff(dayjs())}
                          onFinish={async () => {
                            await reloadAsync();
                          }}
                        />
                      )}
                    </>
                  ) : (
                    OrderStatusEnumMaps[item.status as OrderStatusEnum]
                  )}
                </div>
                <div className={styles.content}>
                  <Image className={styles.image} src={item.assetImage} />
                  <div className={styles.info}>
                    <div className={styles.title}>{item.assetTitle}</div>
                    <div className={styles.cell}>
                      <div className={styles.label}>价格 ¥{item.total}</div>
                      <div className={styles.value}>数量 {item.num}</div>
                    </div>
                    <div className={styles.cell}>
                      <div className={styles.label}>下单时间</div>
                      <div className={styles.value}>
                        {dayjs(item.createdAt).format("YYYY-MM-DD HH:mm")}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={styles.footer}
                  onClick={(e) => {
                    e.stopPropagation();
                    copy(item.no);
                    Toast("复制成功");
                  }}
                >
                  订单编号: {item.no}
                  <Image
                    className={styles.copy}
                    src={require("@/assets/icons/copy.png").default}
                  />
                </div>
              </div>
            );
          })}
        </List>
      )}
    </PullRefresh>
  );
};

export default () => {
  const nav = useNavigate();
  const state = useReactive({
    tab: OrderStatusEnum.PENDING,
    keyword: "",
  });

  const tabs = useMemo(() => {
    return [
      {
        key: OrderStatusEnum.PENDING,
        title: "待支付",
        component: ListView,
        cache: true,
      },
      {
        key: OrderStatusEnum.COMPLETED,
        title: "已支付",
        component: ListView,
        cache: true,
      },
      {
        key: OrderStatusEnum.CANCELED,
        title: "已取消",
        component: ListView,
        cache: true,
      },
    ];
  }, []);

  const page = useMemo(() => {
    return tabs.find((tab) => tab.key == state.tab);
  }, [tabs, state.tab]);

  return (
    <div className={styles.page}>
      <NavBar title="我的订单" />
      <Sticky>
        <Tabs
          className={styles.tabs}
          active={state.tab}
          onChange={(key) => {
            state.tab = key as OrderStatusEnum;
          }}
        >
          {tabs.map((item) => (
            <Tabs.TabPane key={item.key} name={item.key} title={item.title} />
          ))}
        </Tabs>
      </Sticky>
      <KeepAlive
        max={20}
        strategy={"PRE"}
        activeName={state.tab.toString()}
        cache={page?.cache}
      >
        {page && (
          <page.component
            status={page.key}
            onClick={async (item) => {
              nav(`/order/detail/${item.no}`);
            }}
          />
        )}
      </KeepAlive>
    </div>
  );
};
